/* Provide sufficient contrast against white background */

@font-face {
    font-family: 'foundrysterling-bookregular';
    src: url('components/assets/fonts/foundrysterling-book-webfont.woff2') format('woff2'), url('components/assets/fonts/foundrysterling-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'museo_sans100';
    src: url('components/assets/fonts/MuseoSans_100-webfont.eot');
    src: url('components/assets/fonts/MuseoSans_100-webfont.eot?#iefix') format('embedded-opentype'), url('components/assets/fonts/MuseoSans_100-webfont.woff') format('woff'), url('components/assets/fonts/MuseoSans_100-webfont.ttf') format('truetype'), url('components/assets/fonts/MuseoSans_100-webfont.svg#museo_sans100') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'museo_sans100_italic';
    src: url('components/assets/fonts/MuseoSans_100_Italic-webfont.eot');
    src: url('components/assets/fonts/MuseoSans_100_Italic-webfont.eot?#iefix') format('embedded-opentype'), url('components/assets/fonts/MuseoSans_100_Italic-webfont.woff') format('woff'), url('components/assets/fonts/MuseoSans_100_Italic-webfont.ttf') format('truetype'), url('components/assets/fonts/MuseoSans_100_Italic-webfont.svg#museo_sans100_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'museo_sans300';
    src: url('components/assets/fonts/MuseoSans_300-webfont.eot');
    src: url('components/assets/fonts/MuseoSans_300-webfont.eot?#iefix') format('embedded-opentype'), url('components/assets/fonts/MuseoSans_300-webfont.woff') format('woff'), url('components/assets/fonts/MuseoSans_300-webfont.ttf') format('truetype'), url('components/assets/fonts/MuseoSans_300-webfont.svg#museo_sans300') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'museo_sans300_italic';
    src: url('components/assets/fonts/MuseoSans_300_Italic-webfont.eot');
    src: url('components/assets/fonts/MuseoSans_300_Italic-webfont.eot?#iefix') format('embedded-opentype'), url('components/assets/fonts/MuseoSans_300_Italic-webfont.woff') format('woff'), url('components/assets/fonts/MuseoSans_300_Italic-webfont.ttf') format('truetype'), url('components/assets/fonts/MuseoSans_300_Italic-webfont.svg#museo_sans300_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'museo_sans500';
    src: url('components/assets/fonts/MuseoSans_500-webfont.eot');
    src: url('components/assets/fonts/MuseoSans_500-webfont.eot?#iefix') format('embedded-opentype'), url('components/assets/fonts/MuseoSans_500-webfont.woff') format('woff'), url('components/assets/fonts/MuseoSans_500-webfont.ttf') format('truetype'), url('components/assets/fonts/MuseoSans_500-webfont.svg#museo_sans500') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'museo_sans500_italic';
    src: url('components/assets/fonts/MuseoSans_500_Italic-webfont.eot');
    src: url('components/assets/fonts/MuseoSans_500_Italic-webfont.eot?#iefix') format('embedded-opentype'), url('components/assets/fonts/MuseoSans_500_Italic-webfont.woff') format('woff'), url('components/assets/fonts/MuseoSans_500_Italic-webfont.ttf') format('truetype'), url('components/assets/fonts/MuseoSans_500_Italic-webfont.svg#museo_sans500_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'museo_sans700';
    src: url('components/assets/fonts/MuseoSans_700-webfont.eot');
    src: url('components/assets/fonts/MuseoSans_700-webfont.eot?#iefix') format('embedded-opentype'), url('components/assets/fonts/MuseoSans_700-webfont.woff') format('woff'), url('components/assets/fonts/MuseoSans_700-webfont.ttf') format('truetype'), url('components/assets/fonts/MuseoSans_700-webfont.svg#museo_sans700') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'museo_sans700_italic';
    src: url('components/assets/fonts/MuseoSans_700_Italic-webfont.eot');
    src: url('components/assets/fonts/MuseoSans_700_Italic-webfont.eot?#iefix') format('embedded-opentype'), url('components/assets/fonts/MuseoSans_700_Italic-webfont.woff') format('woff'), url('components/assets/fonts/MuseoSans_700_Italic-webfont.ttf') format('truetype'), url('components/assets/fonts/MuseoSans_700_Italic-webfont.svg#museo_sans700_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'museo_sans900';
    src: url('components/assets/fonts/MuseoSans_900-webfont.eot');
    src: url('components/assets/fonts/MuseoSans_900-webfont.eot?#iefix') format('embedded-opentype'), url('components/assets/fonts/MuseoSans_900-webfont.woff') format('woff'), url('components/assets/fonts/MuseoSans_900-webfont.ttf') format('truetype'), url('components/assets/fonts/MuseoSans_900-webfont.svg#museo_sans900') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'museo_sans900_italic';
    src: url('components/assets/fonts/MuseoSans_900_Italic-webfont.eot');
    src: url('components/assets/fonts/MuseoSans_900_Italic-webfont.eot?#iefix') format('embedded-opentype'), url('components/assets/fonts/MuseoSans_900_Italic-webfont.woff') format('woff'), url('components/assets/fonts/MuseoSans_900_Italic-webfont.ttf') format('truetype'), url('components/assets/fonts/MuseoSans_900_Italic-webfont.svg#museo_sans900_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}


html, body, #root {
    background: #F7F7F7;
    color: #444;
    font-size: 14px !important;
    min-height: 100%;
    height: 100%;
}

.container-fluid {
    padding-right: 0;
}

.text-info, .text-guide {
    color: #00AEEF !important;
}

    .text-info:hover {
        color: #ffffff !important;
    }

    .text-guide:hover, .emailLink:hover {
        color: #1b6ec2 !important;
    }

.mainNav a.nav-link {
    color: #5D6770 !important;
    font-family: 'foundrysterling-bookregular';
}

a {
    color: #02568A;
}

    a:hover {
        opacity: .9;
        text-decoration: none !important;
    }

.list-group-item-action {
    color: #02568A;
    border: none;
}

.list-group-item.active {
    background: #02568A;
    color: #fff;
    border: none;
}

.btn.btn-secondary {
    background: #5d6770;
    color: #fff;
    border: none;
}

    .btn.btn-secondary:hover {
        background: #00aced;
        color: #fff;
    }

.mainNav a.nav-link:hover {
    color: #02568A !important;
}

div.logout-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 58px;
    background: rgb(235, 235, 235);
}

a.members-link.nav-link {
    color: #fff !important;
    background-color: #C52033;
    text-align: center;
    font-weight: 300 !important;
    text-transform: none !important;
    font-size: 16px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: fixed;
    bottom: 10px;
    left: 25px;
    width: 150px;
    padding: 4px;
}

    a.members-link.nav-link:hover {
        color: #fff !important;
        background-color: #C52033 !important;
        opacity: .95;
    }

.nav-link {
    color: #02568A;
    font-size: 14px;
    text-transform: capitalize;
    font-family: 'foundrysterling-bookregular';
    padding-top: 10px;
    padding-bottom: 5px;
}

    .nav-link:first-child {
        padding-top: 10px !important;
    }

    .nav-link:hover, .nav-link.active {
        color: white;
        background-color: #02568A !important;
    }

    .nav-link svg {
        margin-right: 5px;
    }

.treeview-icon {
    margin-left: 4px !important;
    margin-top: -25px !important;
    border-left: 1px dotted #ccc;
    border-bottom: 1px dotted #ccc;
    color: transparent;
    font-size: 24px !important;
}

.nav-title {
    background-color: #CFCFCF;
    color: #02568A;
    text-transform: uppercase;
    font-size: 12px;
    padding-left: 10px;
    font-weight: 600;
    z-index: 10;
}

.mainRow {
    width: 100% !important;
    min-height: 100vh;
}

#top-nav-container{
    min-height: 3rem;
}

#wrapper {
    min-height: 100%;
    height: auto !important;
    margin-bottom: -85px; /* the bottom margin is the negative value of the footer's total height */
}

    #wrapper:after {
        content: "";
        display: block;
        height: 65px; /* the footer's total height */
    }

#content {
    height: 100%;
}

footer {
    height: 65px;
    padding-bottom: 10px;
}

footer svg {
    margin-left: 10px;
    margin-top: -3px;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.embedContainer iframe {
    border: none;
}

.st0 {
    opacity: 0.15;
    fill: #292F33;
}

.st1 {
    fill: #FFFFFF;
}


.glossaryLink {
    display: none;
}

.printLink {
    display: block;
}

.toggleBtn {
    display: none;
}

    .toggleBtn:focus {
        box-shadow: none;
    }

.mobilePowerBi {
    display: none;
}

.desktopPowerBi {
    display: block;
}

.hideMobile {
    display: block;
}

.showMobile {
    display: none;
}

.hideVideo {
    display: none !important;
    visibility: hidden;
    opacity: 0;
}

.showVideo {
    display: block !important;
    visibility: visible;
    opacity: 1;
}

[class$="-control"] {
    border: 1px solid #02568A !important;
    border-radius: 0px !important;
    min-height: 30px !important;
    max-height: 30px !important;
    margin-right: 20px;
    box-shadow: none !important;
}

[class$="-ValueContainer"] {
    height: 30px !important;
    font-size: 14px !important;
    padding-top: 0px !important;
    margin-top: -4px !important;
    color: #02568A !important;
}

[class$="-IndicatorsContainer"] {
    height: 30px !important;
}

[class$="-control"] input {
    font-size: 14px !important;
    color: #02568A !important;
}

[class$="-singleValue"] {
    color: #02568A !important;
}


[class$="-option"] {
    font-size: 14px !important;
    color: #02568A !important;
    height: 30px !important;
    padding-top: 5px !important;
    background: #fff !important;
}

    [class$="-option"]:hover, [class$="-option"]:focus {
        background: #E0E0E0 !important;
        cursor: pointer;
        height: 30px !important;
    }

.dropdown-toggle {
    background: #02568A !important;
}

    .dropdown-toggle:hover {
        opacity: .9;
    }

    .dropdown-toggle:focus {
        outline: 0 !important;
        box-shadow: none !important;
    }

    .dropdown-toggle::after {
        font-size: 14px;
    }

.dropdown-menu button {
    font-size: 14px !important;
    color: #02568A !important;
}

.btn.disabled, .btn.disabled:hover {
    background-color: #5d6770 !important;
}

nav {
    padding-right: 0 !important;
}

ul.leftNav div.card div.card-header {
    cursor: pointer;
    color: rgb(2,86,138);
    background-color: rgb(219,219,219);
    font-weight: 500;
}

ul.leftNav div.card div.card-header:hover {
    background-color: rgb(213,213,213);
}

h1 {
    color: #02568A !important;
    font-size: 2rem;
    float: left;
}

.help {
    color: #fff !important;
    font-size: 1.5rem;
    padding: 0px;
    background: #02568A;
    padding-left: 20px !important;
}

    .help svg {
        color: #fff !important;
        font-size: 28px !important;
    }

.help-topic {
    color: #fff !important;
    font-size: 1.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #02568A;
    padding-left: 20px !important;
    margin-bottom: 20px;
}

[class$="MuiAccordionSummary-content"].Mui-expanded {
    margin: 0 !important;
}

.MuiAccordionSummary-expandIconWrapper {
    margin-right: 10px !important;
}

a.support-link.nav-link {
    color: #fff !important;
    background-color: #C52033;
    text-align: center;
    font-weight: 300 !important;
    text-transform: none !important;
    font-size: 16px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 148px;
    float: right;
    margin-top: 42px;
    padding: 4px;
}

    a.support-link.nav-link:hover {
        color: #fff !important;
        background-color: #C52033 !important;
        opacity: .95;
    }

.clearfix {
    clear: both;
}

.card {
    border-radius: 0px !important;
}

.card-body {
    padding: 0 !important;
}

.card-title {
    font-weight: 600;
    padding: 20px;
}

.card-body a {
    width: 100%;
    border-radius: 0px !important;
    background: #02568A !important;
}

    .card-body a:hover {
        opacity: .9;
    }

.breadcrumb {
    background: #E0E0E0;
    border-radius: 0px;
    margin-bottom: 0px;
}

    .breadcrumb a {
        color: #02568A;
    }

        .breadcrumb a:hover {
            text-decoration: none;
            opacity: .9;
        }

.breadcrumb-item + .breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider,">") !important;
}

.phone {
    margin-left: 15px;
}

.navbar {
    padding: 0 !important
}

.MuiPaper-root .navbar {
    box-shadow: none !important;
}

.navbar .container {
    width: 85% !important;
    position: fixed;
    right: 30px;
    top: 0;
    padding: 10px !important;
    border-bottom-left-radius: 10px;
}

[class$="MuiBackdrop-root-MuiModal-backdrop"], [class$="MuiPaper-root-MuiDrawer-paper"] {
    box-shadow: none !important;
    background-color: transparent !important;
    cursor: pointer !important;
}



[class$="css-1myizsv-MuiPaper-root-MuiDrawer-paper"] {
    border-bottom: none !important
}
.css-myz6v0 {
    border-bottom: none !important
}



.top-drawer-toggle-btn {
    padding: 0 15px !important;
    background-color: #f0f0f0 !important;
    border-radius: 0px 0px 5px 5px !important;
    margin-bottom: 10px !important;
    height: 25px;
    font-size: 10px !important;
    position: fixed !important;
    top: 0;
    right: 15px;
    z-index: 9999;
}

.top-drawer-toggle-btn-closed {
    z-index: 0 !important;
    position: absolute;
    right: 30px;
    top: 49px;
    z-index: -1 !important;
}

.top-drawer-toggle-btn svg {
    margin-left: 5px;
    color: #1976d2;
}

.report-wrapper {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.embed-responsive {
    padding: 0px !important;
    margin: 30px auto 0 auto;
    position: relative;
    width: 100%;
    height: 100vh;
    top: 50%;
}

.left-drawer-toggle-container {
    background: #f0f0f0;
    padding: 0;
    min-width: 25px;
    max-width: 25px;
    height: 100vh;
    position: fixed;
    z-index: 999;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-left: 1px solid #E8E8E8 !important;
}

    .left-drawer-toggle-container .toggle-btn {
        width: 100%;
        height: 80%;
        border: none;
        background: #f0f0f0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

[aria-label="breadcrumb"] {
    padding-left: 0px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
}

    [aria-label="breadcrumb"] * {
        padding-left: 0px !important;
        padding-top: 0px !important;
        margin-top: 0px !important;
    }

.error {
    z-index: 999999 !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 0px !important;
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
    background-color: #C52033 !important;
    height: 30px !important;
    padding-top: 0px !important;
}

    .error button {
        z-index: 99999 !important;
    }

    .error svg:first-child {
        margin-top: -2px !important;
    }

.privacy-mobile {
    display: none;
}

.privacy-desktop {
    display: block;
}

/* TABLET VIEW */
@media only screen and (max-width:1080px) {

    #wrapper {
        margin-bottom: -85px !important;
    }

    footer {
        height: 65px !important;
    }


    .show {
        display: block !important;
        visibility: visible;
        opacity: 1;
    }

    .hide {
        display: none !important;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
    }

    .navbar .container {
        right: 15px;
    }

    .top-drawer-toggle-btn-closed {
        right: 15px;
    }
}

/* MOBILE VIEW */

@media only screen and (max-width: 640px) {

    #wrapper {
        margin-bottom: -100px !important;
    }

    #content {
        height: 70% !important;
    }

    footer {
        height: 155px !important;
        width: 100%;
        margin-left: 10px;
        padding-left: 10px;
    }

    .glossaryLink {
        display: block;
    }

    .printLink {
        display: none;
    }

    .navbarBrandStyle {
        width: 160px;
    }

    .mobilePowerBi {
        display: block;
    }

    .desktopPowerBi {
        display: none;
    }

    .hideMobile {
        display: none;
    }

    .showMobile {
        display: block;
    }

    .embed-responsive {
        height: 100vh !important;
        width: 100%;
        margin-left: 10px;
        margin-top: 0;
    }

    .privacy-mobile {
        display: block;
    }

    .privacy-desktop {
        display: none;
    }

    .navbar .container {
        width: 70% !important;
        right: 20px;
        text-align: center !important;
    }

    .top-drawer-toggle-btn {
        right: 20px;
    }

    .top-drawer-toggle-btn-closed {
        right: 20px;
    }

    .left-drawer-toggle-container .toggle-btn {
        height: 100%;
        align-items: normal;
        padding-top: 40px;
        color: #1976d2;
    }

    .error {
        height: auto !important;
    }
}
